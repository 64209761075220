<template>
  <section>
    <NavBody>
      <template #body>
        <p class="font-bold text-2xl text-brand-primary">{{ userDetails.name.split(' ')[0] }}, let’s see how your referrals are doing</p>
        <p class="font-normal text-base text-[#8C94A6]">Have a beautiful {{ greeting }}!</p>
      </template>
    </NavBody>
    <div class="mx-auto lg:max-w-[80rem] z-20 gap-6 w-full px-4 lg:px-0 bg-[#FAFAFA] mt-40">
      <br>
      <br>
      <div class="scrollbar-hide">
        <div class="bg-white border border-[#F3F4F6] rounded-xl p-5 lg:px-6 lg:py-8">
          <!-- summary -->
          <div class="grid gap-4 md:grid-flow-col md:justify-stretch">
            <div class="border bg-white rounded-xl p-4 border-[#DEDEDE]" v-for="item in summary" :key="item">
              <p class="text-brand-gray text-xs mb-2">{{ item.title }}</p>
              <div class="flex items-center gap-2">
                <img src="@/assets/images/group.svg" alt="" />
                <p class="font-bold text-3xl text-brand-primary">{{ item.count }}</p>
              </div>
            </div>
          </div>
          <!-- filter -->
          <div class="flex justify-between items-center my-6">
            <p class="text-[#687588] text-lg text-bold">Here’s your referral list</p>
            <!-- <div class="flex items-center gap-4">
              <div class="bg-white border border-[#DEDEDE] rounded-lg px-4 py-3 text-brand-primary flex items-center gap-2">
                <Filter />
                <p>Filter</p>
              </div>
              <div class="bg-white border border-[#DEDEDE] rounded-lg px-4 py-3 text-brand-primary flex items-center gap-2">
                <Calendar />
                <p class="text-brand-gray">Jun 01- Jun 30</p>
              </div>
            </div> -->
          </div>
          <!-- table -->
          <div class="relative overflow-x-auto" v-if="pageLoaded">
            <table class="w-full text-sm text-left rtl:text-right text-white" v-if="referrals.length >= 1">
              <thead class="text-xs font-bold text-white uppercase bg-brand-primary">
                <tr>
                  <th scope="col" class="p-4" v-for="item in tableHead" :key="item">{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-b border-[#F1F2F4]" v-for="item in referrals" :key="item">
                  <th scope="row" class="px-6 py-4 font-medium text-brand-primary whitespace-nowrap dark:text-white">
                    <div>
                      <img src="" alt="" />
                      <div>
                        <p class="text-xs text-brand-primary capitalize">{{ item.candidate.name || item.first_name + ' ' + item.last_name }}</p>
                        <p class="text-brand-gray text-[10px] capitalize">{{ item.candidate.role_title }}</p>
                      </div>
                    </div>
                  </th>
                  <td class="px-6 py-4">
                    <div>
                      <img src="" alt="" />
                      <p class="text-brand-primary text-xs capitalize">{{ item.employerJob.company_name || 'Nil' }}</p>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div>
                      <p class="text-xs text-brand-primary capitalize">{{ item.employerJob.role_title || 'N/A' }}</p>
                      <div class="text-brand-gray text-[10px] gap-x-1 flex items-center capitalize">
                        <p v-if="item.employerJob.work_type">{{ item.employerJob.work_type }}</p>
                        <i class="text-lg mb-2">.</i>
                        <p v-if="item.employerJob.country_name">{{ item.employerJob.country_name }}</p>
                        <!-- <i class="text-lg mb-2">.</i>
                        <p>Exp 0-1 years</p> -->
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4">
                    <div>
                      <p class="text-[10px] text-brand-primary capitalize">{{ item.job_application_status || 'N/A' }}</p>
                    </div>
                  </td>
                  <td class="px-6 py-4 text-brand-primary text-xs">{{ moment(item.created_at).format(' Do MMM YYYY') }}</td>
                </tr>
              </tbody>
            </table>
            <div v-else class="text-center">
              <p class="font-albertSemiBold text-2xl">No referrals yet</p>
              <button @click.prevent="router.push('/jobs')" class="bg-brand-primary text-white rounded-xl p-3">Refer Candidate</button>
            </div>
          </div>

          <!-- <Paginate
          data-test="paginate"
          @next="
            currentPage += 1;
            getJobs();
          "
          @prev="
            currentPage > 1 ? (currentPage -= 1) : currentPage;
            getJobs();
          "
        /> -->
        </div>
        <!-- refer link -->
        <div class="my-6 grid md:grid-cols-2 gap-6" v-if="pageLoaded">
          <div class="px-6 py-8 bg-white rounded-[10px] border border-[#F3F4F6] flex flex-col justify-between">
            <div class="mb-6">
              <p class="text-2xl font-albert text-brand-primary">Active Referrals</p>
              <p class="text-base text-[#8C94A6]">Here is a list of everyone you have referred on the platform</p>
            </div>
            <div v-if="summaryCount.all > 0" class="bg-[#FEFAF1] py-8 px-5 flex items-center justify-between rounded-[20px]">
              <div>
                <p>You have referred {{ summaryCount.all }} people</p>
                <div class="flex -space-x-4 rtl:space-x-reverse">
                  <img
                    v-for="i in summaryCount.all"
                    :key="i"
                    class="w-10 h-10 border-2 border-white rounded-full"
                    src="@/assets/images/whiteAva.svg"
                    alt=""
                  />
                  <!-- <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="@/assets/images/girlAva.svg" alt="" /> -->
                  <a
                    v-if="summaryCount.all > 9"
                    class="flex items-center justify-center w-10 h-10 text-xs font-medium text-black bg-gray-200 border-2 border-white rounded-full"
                    href="#"
                    >+{{ summaryCount.all - 9 }}</a
                  >
                </div>
              </div>
            </div>
            <div v-else class="bg-[#FEFAF1] py-8 px-5 flex items-center justify-between rounded-[20px]">
              <div>
                <div class="flex -space-x-4 rtl:space-x-reverse">
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/whiteAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/girlAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/whiteAva.svg" alt="" />
                  <img class="w-10 h-10 rounded-full" src="@/assets/images/Avatar.svg" alt="" />
                </div>
              </div>
              <p class="font-albertSemiBold text-sm">Sorry, you have not referred anyone at the moment</p>
            </div>
          </div>
          <div class="px-6 py-8 bg-white rounded-[10px] border border-[#F3F4F6]">
            <p class="mb-8 text-2xl font-albertSemiBold">Refer your network to join CareerBuddy</p>
            <div class="bg-[#FBE9F2] rounded-[20px] pl-6 flex items-center justify-between">
              <div class="py-6 w-[40%]">
                <p class="mb-4 font-albertSemiBold text-brand-primary">
                  Get your own referral link and invite your fellow recruiter to join careerbuddy
                </p>
                <button @click="copyReferral" class="px-3 py-2 bg-brand-primary rounded-[10px] text-white flex items-center gap-x-2">
                  Copy Referral Link <Copy />
                </button>
              </div>
              <div class="relative">
                <img src="@/assets/images/circleBackgrounds.svg" alt="" class="" />
                <img src="@/assets/images/refer.svg" alt="" class="absolute top-2 right-[18px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :is-loading="!pageLoaded" className="flex justify-center" />
    </div>
  </section>
</template>

<script setup>
import Card from '@/components/jobCard.vue';
import NavBody from '@/components/NavBody.vue';
import Paginate from '@/components/Paginate.vue';
import { ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import Loading from '@/components/Loading.vue';
import * as moment from 'moment';
// import Calendar from '@/assets/icons/calendarRefer.svg?inline';
// import Filter from '@/assets/icons/referFilter.svg?inline';

const store = useStore();
const router = useRouter();

const pageLoaded = ref(false);
const currentPage = ref(1);
const referrals = ref([]);

const tableHead = ref(['Candidate', 'Company', 'Job Title', 'Status', 'Created Date']);

const userDetails = computed(() => store.getters['auth/userDetails']);
const summaryCount = computed(() => store.getters['global/getReferralCount']);

const summary = computed(() => {
  const sumCount = store.getters['global/getReferralCount'];
  return [
    {
      title: 'All Candidates',
      count: sumCount.all,
    },
    {
      title: 'Shortlisted Candidates',
      count: sumCount.shortlisted,
    },
    {
      title: 'Interviewed Candidates',
      count: sumCount.interviewed,
    },
    {
      title: 'Hired Candidates',
      count: sumCount.hired,
    },
    {
      title: 'Rejected Candidates',
      count: sumCount.rejected,
    },
  ];
});

const greeting = computed(() => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return 'Morning';
  } else if (currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
});

const copyReferral = () => {
  navigator.clipboard
    .writeText(
      `https://candidate.thecareerbuddy.com/register?referralId=${userDetails.value.uuid}
`,
    )
    .then(() => {
      alert('Text copied to clipboard!');
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
    });
};

onBeforeMount(async () => {
  await store.dispatch('global/referralCount');
  // summaryCount.value = await store.dispatch('global/referralCount');
  console.log(summaryCount.value, '>>>>>>>>>>>>>>>>>>>>>>');
  referrals.value = await store.dispatch('global/getAllReferrals');
  pageLoaded.value = true;
});
</script>
