<template>
  <section class="relative" v-for="(detail, idx) in details" :key="idx">
    <div data-test="job-card-section" class="bg-white rounded-3xl p-8 shadow lg:w-[400px] lg:h-[650px]">
      <div>
        <img :src="detail.employer?.banner_url || hiringImg" alt="" class="object-cover w-full h-32 cursor-pointer" @click="viewJob(detail)" />
        <div class="my-6">
          <div class="flex items-center gap-4">
            <img :src="detail.employer.logo_url" alt="logo" class="lg:w-12 lg:h-12 h-6 w-6 rounded-full object-cover mr-2" />
            <p>{{ detail?.employer?.name }}</p>
          </div>
          <div class="my-[19px]">
            <p class="font-albertBold text-2xl capitalize cursor-pointer" @click="viewJob(detail)">{{ role(detail) }}</p>
            <div v-html="truncate(trimStart(detail.description), { length: 150 })" class="description"></div>
          </div>
          <div class="flex items-center mb-[19px]">
            <Map />
            <p class="text-[#64666C] text-sm ml-2">{{ detail.state?.name }} {{ detail.country?.name }}, {{ detail.country?.code }}</p>
          </div>
          <div class="mb-4 gap-2 flex justify-between items-center">
            <div class="flex items-center gap-2">
              <p class="px-4 py-2 bg-[#F1F1F1] rounded-[52px] whitespace-nowrap capitalize">{{ detail.type.split('_').join(' ') }}</p>
              <p class="px-4 py-2 bg-[#F1F1F1] rounded-[52px] capitalize">{{ detail.work_type }}</p>
            </div>
            <div @click="viewJob(detail)" class="px-4 py-3 bg-brand-black text-white rounded-[52px] text-center whitespace-nowrap cursor-pointer">
              More Details
            </div>
          </div>
          <hr />
          <div class="flex items-center justify-between mt-6">
            <div class="flex items-center">
              <!-- <Money /> -->
              <p class="text-base font-bold text-brand-black ml-2">
                {{ detail?.salary_currency }} {{ formatNumber(detail?.salary_min) }} - {{ formatNumber(detail?.salary_max) }}
                <span class="font-normal">/{{ pay_duration(detail?.pay_period) }}</span>
              </p>
            </div>
            <div class="flex items-center whitespace-nowrap">
              <Calenda />
              <p class="ml-2">{{ moment(detail.created_at, 'YYYYMMDD').fromNow() }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { formatNumber, formatTitle } from '@/utils/helper';
import { computed, onMounted, ref } from 'vue';
import { truncate, trimStart } from 'lodash';
import Money from '@/assets/icons/money.svg?inline';
import Calenda from '@/assets/icons/calendaOne.svg?inline';
import Map from '@/assets/icons/mapPin.svg?inline';
import * as moment from 'moment';
import hiringImg from '@/assets/images/hiring.jpg';

const router = useRouter();
const props = defineProps({
  details: { type: Array },
  viewType: { type: String },
  path: String,
});

const role = (detail) => detail.role_title || detail.job.title_name;

const viewJob = (job) => {
  router.push(`/${formatTitle(job?.employer?.name)}/${job?.fid}/${formatTitle(job?.role_title)}`);
};

const pay_duration = (duration) => {
  const durationList = duration.split('_');
  return durationList.length >= 2 ? durationList[1] : durationList.join(' ');
};

onMounted(() => {
  // console.log(props.details, '....................');
});
</script>
